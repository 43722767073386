import { useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { QuestionFormValue } from '../../types/questionPageTypings';
import { useTryToRequestQuestionPage } from './useTryToRequestQuestionPage';
import { useTryToRequestUpdateQuestionPage } from './useTryToRequestUpdateQuestionPage';

interface UseRequestQuestionPageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  titlePage: string;
  setValue: UseFormSetValue<QuestionFormValue>;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useRequestQuestionPage({
  setTitlePage,
  setValue,
  isUpdate,
  setIsUpdate,
  titlePage,
}: UseRequestQuestionPageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');

  const { tryToRequestQuestionPage } = useTryToRequestQuestionPage();
  const { tryToRequestUpdateQuestionPage, isLoading } =
    useTryToRequestUpdateQuestionPage();

  const handleRequestQuestionPage = useCallback(async () => {
    await tryToRequestQuestionPage(pageId as string).then(response => {
      setValue('cancellationFunnelId', `${response.cancellationFunnelId}`);
      setValue('title', response.title);
      setValue('subtitle', response.subtitle);
      setValue('answers', response.answers);
      setValue(
        'continueCancelationButtonTitle',
        response.continueCancelationButtonTitle
      );
      setValue(
        'keepSubscriptionButtonTitle',
        response.keepSubscriptionButtonTitle
      );
      setTitlePage(response.name);
    });
  }, [pageId, setTitlePage]);

  const handleUpdateQuestionPage = useCallback(
    async (e: QuestionFormValue) => {
      await tryToRequestUpdateQuestionPage({
        id: pageId as string,
        payload: {
          cancellationFunnelId: Number(e.cancellationFunnelId),
          pageType: 'question',
          name: titlePage,
          active: true,
          content: {
            ...e,
            keepSubscriptionButtonTitle: e.keepSubscriptionButtonTitle || null,
            continueCancelationButtonTitle:
              e.continueCancelationButtonTitle || null,
          },
        },
      });
    },
    [pageId, useTryToRequestUpdateQuestionPage, titlePage]
  );

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestQuestionPage();
      setIsUpdate(true);
    }
  }, [pageId]);

  return { handleUpdateQuestionPage, isLoadingUpdate: isLoading };
}
