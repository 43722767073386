import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, TextAreaController } from '@organisms';
import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import { BackButton } from 'src/app/components/BackButton';
import { CancelationButtonTitleFormFields } from 'src/features/Funnel/Components/formFields/CancelationButtonTitleFormFields';
import { useRequestQuestionPage } from 'src/features/Funnel/hooks/question/useRequestQuestionPage';
import { useTryToRequestCreatingQuestionFunnelPage } from 'src/features/Funnel/hooks/question/useTryToRequestCreatingQuestionFunnelPage';
import { ParamasProps } from 'src/features/Funnel/types/FunnelEditTypings';
import { QuestionFormValue } from 'src/features/Funnel/types/questionPageTypings';

const answersSchema = yup.object().shape({
  answer: yup.string().required('A Resposta é obrigatória'),
});

const validationSchema = yup.object({
  title: yup.string().required('O Título é obrigatório'),
  subtitle: yup.string().required('O Subtítulo é obrigatório'),
  answers: yup
    .array()
    .of(answersSchema)
    .required('Informe ao menos uma resposta'),
  continueCancelationButtonTitle: yup.string().nullable(),
  keepSubscriptionButtonTitle: yup.string().nullable(),
});

export const FunnelPageQuestionPageScreen: React.FC = () => {
  const inputTitleRef = useRef<any>(null);
  const [titlePage, setTitlePage] = useState('Modal Pergunta');
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const history = useHistory();

  const { id } = useParams<ParamasProps>();
  const { control, handleSubmit, setValue } = useForm<QuestionFormValue>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cancellationFunnelId: '',
      title: '',
      subtitle: '',
      answers: [{ answer: '' }, { answer: '' }],
      continueCancelationButtonTitle: null,
      keepSubscriptionButtonTitle: null,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  });
  const { isLoading, tryToRequestCreatingQuestion } =
    useTryToRequestCreatingQuestionFunnelPage();
  const { handleUpdateQuestionPage, isLoadingUpdate } = useRequestQuestionPage({
    isUpdate,
    setIsUpdate,
    setTitlePage,
    setValue,
    titlePage,
  });

  const handleEditTitlePage = useCallback(() => {
    if (inputTitleRef.current.input.value) {
      setTitlePage(inputTitleRef.current.input.value);
    }
    setIsEditTitle(false);
  }, []);

  const handleAddingQuestionPage = useCallback(
    async (e: QuestionFormValue) => {
      if (isUpdate) {
        return handleUpdateQuestionPage(e);
      }
      await tryToRequestCreatingQuestion({
        cancellationFunnelId: Number(e.cancellationFunnelId),
        pageType: 'question',
        name: titlePage,
        active: true,
        content: { ...e },
      });
    },
    [
      isUpdate,
      handleUpdateQuestionPage,
      tryToRequestCreatingQuestion,
      titlePage,
    ]
  );

  useEffect(() => {
    setValue('cancellationFunnelId', id);
  }, [id]);

  return (
    <Col
      span={24}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '22px',
      }}
    >
      <BackButton />
      <Row style={{ justifyContent: 'center' }}>
        <Col
          span={12}
          style={{
            minWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Row style={{ alignItems: 'center' }}>
            {isEditTitle ? (
              <>
                <InputTitle ref={inputTitleRef} defaultValue={titlePage} />
                <CheckOutlined
                  style={{
                    color: '#3C89E8',
                    fontSize: '16px',
                    marginLeft: '16px',
                    cursor: 'pointer',
                  }}
                  data-testid='saveEditTitlePage'
                  onClick={handleEditTitlePage}
                />
                <CloseOutlined
                  style={{
                    color: '#FFFFFF',
                    fontSize: '16px',
                    marginLeft: '16px',
                    cursor: 'pointer',
                    opacity: 0.45,
                  }}
                  data-testid='closeEditTitlePage'
                  onClick={() => setIsEditTitle(false)}
                />
              </>
            ) : (
              <>
                <Typography.Title
                  level={4}
                  style={{ marginBottom: '0px' }}
                  data-testid='titlePage'
                >
                  {titlePage}
                </Typography.Title>
                <EditOutlined
                  style={{
                    color: '#1668DC',
                    fontSize: '24px',
                    marginLeft: '9px',
                    cursor: 'pointer',
                  }}
                  data-testid='editTitlePage'
                  onClick={() => {
                    setIsEditTitle(true);
                  }}
                />
              </>
            )}
          </Row>
          <Row>
            <Form
              layout='vertical'
              onFinish={handleSubmit(handleAddingQuestionPage)}
              requiredMark={'optional'}
              style={{
                width: '100%',
                gap: '24px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Controller
                control={control}
                name='title'
                placeholder='Escreva o principal título da página.'
                defaultValue={control._defaultValues.title}
                autoComplete='off'
                label='Título Principal'
                required
              />
              <TextAreaController
                control={control}
                name='subtitle'
                placeholder='Área de texto'
                defaultValue={control._defaultValues.subtitle}
                autoComplete='off'
                label='Subtítulo'
                isMaxLength={true}
                maxLength={250}
                row={2}
                required
              />
              {fields.map((field, index) => (
                <Row key={field.id} style={{ alignItems: 'center' }}>
                  <Col flex={1}>
                    <Controller
                      control={control}
                      name={`answers[${index}].answer`}
                      placeholder='Escreva a resposta'
                      defaultValue={field.answer}
                      key={field.answer}
                      autoComplete='off'
                      label={`Resposta ${index + 1}`}
                      required
                      showCount={true}
                      maxLength={250}
                    />
                  </Col>
                  <Button
                    type='text'
                    style={{
                      marginLeft: '16px',
                      minWidth: 'auto',
                      padding: '1px 6px',
                    }}
                    onClick={() => remove(index)}
                    disabled={fields.length <= 2}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    />
                  </Button>
                </Row>
              ))}
              <Row style={{ justifyContent: 'center' }}>
                <Col>
                  <Button
                    type='text'
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      append({ answer: '' });
                    }}
                    disabled={fields.length >= 5}
                  >
                    Adicionar mais uma opção
                  </Button>
                </Col>
              </Row>
              <CancelationButtonTitleFormFields control={control} />

              <Divider orientation='center' style={{ marginTop: '398px' }} />
              <Row style={{ justifyContent: 'end' }}>
                <Row style={{ gap: '8px' }}>
                  <Button type='default' onClick={() => history.goBack()}>
                    Cancelar
                  </Button>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={isUpdate ? isLoadingUpdate : isLoading}
                  >
                    Salvar
                  </Button>
                </Row>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const InputTitle = styled(Input)`
  width: auto;
  border: 0px;
  border-bottom: 1px solid #177ddc;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  &:active {
    border: 0px;
  }
  &:focus {
    border: 0px;
    border-bottom: 1px solid #177ddc;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.04);
  }
`;
