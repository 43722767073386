// import { coupons } from '@service/api';
import { useCallback, useState } from 'react';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { useQueryParams } from './useQueryParams';

export const OPTIONS_TAB = ['Produto Físico', 'Produto Digital'];

export const useCoupon = () => {
  const query = useQueryParams();

  const [tabActive, setTabActive] = useState(() => {
    const showParams = query.get('show');
    const tabDefault = '0';

    if (!showParams || showParams === 'physic') return tabDefault;

    return showParams === 'digital' ? '1' : tabDefault;
  });

  const [isSelectAddCouponModalOpen, setIsSelectAddCouponModalOpen] =
    useState(false);

  const handleOpenModalCoupon = useCallback(
    () => setIsSelectAddCouponModalOpen(true),
    []
  );

  const handleCloseModalCoupon = useCallback(
    () => setIsSelectAddCouponModalOpen(false),
    []
  );

  useBreadcrumbs([
    {
      label: 'Gestão de Cupons',
    },
  ]);

  return {
    isSelectAddCouponModalOpen,
    handleCloseModalCoupon,
    handleOpenModalCoupon,
    tabActive,
    setTabActive,
  };
};
