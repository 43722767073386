import { OPTIONS_TAB, useCoupon } from '@hooks';
import { PageHeader } from '@organisms';
import React from 'react';

import { TabComponent } from 'src/app/components/antd/TabComponent';

import { AddCouponModal } from './components/AddCouponModal/AddCouponModal.component';
import { CouponsDigitalProducts } from './components/ListCoupons/CouponsDigitalProducts';
import { CouponsPhysicalProducts } from './components/ListCoupons/CouponsPhysicalProducts';

export const CouponPage: React.FC = () => {
  const {
    isSelectAddCouponModalOpen,
    handleCloseModalCoupon,
    handleOpenModalCoupon,
    tabActive,
    setTabActive,
  } = useCoupon();

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Gestão de Cupons'}
        subTitle={'Cadastrar, editar e configurar todos os cupons do checkout.'}
      />
      <TabComponent
        tabList={OPTIONS_TAB}
        onChangeTab={setTabActive}
        defaultActiveKey={tabActive}
      />
      {tabActive === '0' && (
        <CouponsPhysicalProducts onOpenModalCoupon={handleOpenModalCoupon} />
      )}
      {tabActive === '1' && (
        <CouponsDigitalProducts onOpenModalCoupon={handleOpenModalCoupon} />
      )}
      <AddCouponModal
        modalIsOpen={isSelectAddCouponModalOpen}
        onCloseModal={handleCloseModalCoupon}
      />
    </>
  );
};
